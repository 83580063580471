import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CouponService {
  baseUrl = 'https://livelife-za-coupons-api.azurewebsites.net/api/coupons/';
  selectedCoupons: string[];

  constructor(
    private http: HttpClient) { }

    // gets the coupon list from the middleware api
    getCouponsByCategory(categoryId) {
      return this.http.get(`${this.baseUrl}?categoryId=${categoryId}`);
    };

    // gets the coupon list by category
    getCoupons() {
      return this.http.get(this.baseUrl);
    };

    // collects the coupon for the user
    collectCoupon(userId, couponId) {
      return this.http.get(`${this.baseUrl}collectcoupon/${userId}/${couponId}`);
    };

    // collects the coupon for the user
    getUserCoupons(userId) {
      return this.http.get(`${this.baseUrl}getusercoupons/${userId}`);
    };

    // collects the coupon for the user
    userRedeemCoupons(redeemRequest) {
      return this.http.post(this.baseUrl + 'userredeemcoupons', redeemRequest);
    };
}
