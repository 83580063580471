import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { ApiService } from '../services/api.service';
import { CouponService } from '../services/coupon.service';
import { SACouponsService } from '../services/coupons/sacoupons.service';
import { UserService } from '../services/user.service';
import { UserOldService } from '../services/user_old.service';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.page.html',
  styleUrls: ['./coupons.page.scss']
})
export class CouponsPage implements OnInit {

  errorMessage = '';
  coupons;
  retailers;
  error;
  itemNumShow;
  itemNumbers;
  cart;
  currentUser;
  netReadyUser;
  added;
  showLoader = true;
  selectedLevel;
  couponId: number;
  categoryId: number;

  constructor(
    private http: ApiService,
    private userOldService: UserOldService,
    private userService: UserService,
    private couponService: CouponService,
    private saCouponService: SACouponsService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingController: LoadingController,
    private alertController: AlertController
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.couponId = params.couponId;
      this.categoryId = params.categoryId;

      this.userOldService.getUser().subscribe(user => {
        console.log('User',user);
      });

      // this.saCouponService.getSubscription(this.netReadyUser.id, coupon.id ).subscribe(result => {
      //   //this.added = true;
      //   this.presentAlertConfirm();
      // });

      //this.router.navigate(['coupons-subscription-options']);
      // this.fetchData();
    });
  }

  browseCoupons() {
    this.router.navigate(['coupons-retailers']);
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      //cssClass: 'my-custom-class',
      message: 'Loading coupons...',
      //duration: 2000
    });
    await loading.present();

    //this.fetchData();

    await loading.dismiss();
    // const { role, data } = await loading.onDidDismiss();
    // console.log('Loading dismissed!');
  }

  collectCoupon(coupon)
  {
    this.couponService.collectCoupon(this.netReadyUser.id, coupon.id ).subscribe(result => {
      //this.added = true;
      this.presentAlertConfirm();
    });
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Coupons',
      message: 'Coupon collected successfully! Go to your shopping cart?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes',
          handler: () => {
            this.router.navigate(['shopping-cart']);
          }
        }
      ]
    });

    await alert.present();
  }

  // terms and conditions
  termsAndConditions() {
    this.router.navigate(['CouponTermsAndConditions']);
  }

  subscribe(subscriptionType)
  {
    this.router.navigateByUrl(`/coupons-subscribe/${subscriptionType}`);
    // this.router.navigate(['coupons-subscribe']);
  }

  // getting everything from the API
 fetchData() {
    // show busy indicator
    this.loadingController.create({
      keyboardClose: true,
      message: 'Loading coupons...'
    }).then(loadingElement => {
      loadingElement.present();

      this.userOldService.getUser().subscribe(res => {
        this.currentUser = res;
        // get the user's profile data
        this.userService.getUserProfile(this.currentUser.app_id).subscribe(response => {
          this.netReadyUser = response;
        });

        const cartPayload = {
          id: this.currentUser.id
        };

        this.http.post('cart/items', cartPayload).subscribe(result => {
          this.cart = result;
          if (this.cart.length !== 0) {
            this.itemNumShow = true;
          }
          this.itemNumbers = this.cart.length;

          // get the coupons
          if (this.categoryId != null)
          {
            this.couponService.getCouponsByCategory(this.categoryId).subscribe(coupons => {
              this.coupons = coupons;
              loadingElement.dismiss();
              this.showLoader = false;
            });
          }
          else
          {
            this.couponService.getCoupons().subscribe(coupons => {
              //console.log("Coupons", result);
              this.coupons = coupons;

              if (this.coupons && this.couponId)
              {
                const couponList: any[] = [];
                const cid: number = this.couponId;
                this.coupons.forEach((item, i) => {
                  if(item.id === cid){
                    couponList.push(item);
                  }
                });
                this.coupons = couponList;
              }

              loadingElement.dismiss();
              this.showLoader = false;
            });
          }
        });
      });
    });
  }
}
