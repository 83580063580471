import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  token = '';
  // base = 'https://live-life-api.herokuapp.com/api/v1/'; old base
  base = 'https://prod1.api.livelifesolutions.co.za/api/v1/';

  constructor(private http: HttpClient) {
    /*console.log(environment);
    if(environment.API_URL) {
      this.base = environment.API_URL;
    } else {
      this.base ='https://live-life-api-staging.herokuapp.com/api/v1/';
    }*/
  }

  post(url, payload) {
    const header = new HttpHeaders({
      'content-type': 'application/json',
      authorisation: 'bearer V2VKcUw1TTRnTUpPbWNBRG5yN0djUGowelNESUY4TzNZUENxZzNhSQ=='
    });
    const options = { headers: header };
    // console.log('Api - Enter post: ', url, payload);
    const full = this.base + url;

    return new Observable(o => {
      //console.log('Api - http post: ', full, payload, options);
      this.http.post(full, payload).subscribe(
        response => {
          o.next(response);
          //console.log('response from api: ', response);
        },
        error => {
          console.error('Error in API');
          //console.log(error.error);
          o.error(error);
        }
      );
    });
  }

  get(url) {
    const full = this.base + url;
    //console.log('Api - http get: ', url);
    return new Observable(o => {
      this.http.get(full).subscribe(
        response => {
          o.next(response);
          //console.log('response from api: ', response);
        },
        error => {
          //console.error('Error in API');
          //console.log(error.error);
          o.error(error);
        }
      );
    });
  }
}
