import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserOldService {
  user;
  cart;
  result;

  constructor(private http: ApiService) { }

  getUser() {
    // return this.user;
    return new Observable(o => {
      o.next(this.user);
      //console.log('returned user: ', this.user);
    });
  }

  setUser(payload) {
    this.user = payload;
    //console.log('user set: ', this.user);
  }

  setCart(payload) {
    this.cart = payload;
    //console.log('Cart Saved: ', this.cart);
  }

  getCart() {
    return new Observable(o => {
      o.next(this.cart);
      //console.log('returned cart: ', this.cart);
    });
  }
}
